"use client";
import { Button, Stack, Typography } from "@mui/material";

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <html lang={"en"}>
      <body>
        <Stack spacing={2} bgcolor={"#000000"}>
          <Typography variant={"h2"}>Something went wrong!</Typography>
          <Typography variant={"h3"}>{error.message}</Typography>
          <Button
            onClick={() => reset()}
            variant={"contained"}
            color={"primary"}
          >
            Try again
          </Button>
        </Stack>
      </body>
    </html>
  );
}
